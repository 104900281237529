import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// Screens
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import AuthorPage from './pages/AuthorPage';
import GravesPage from './pages/GravesPage';
import FeaturedGravePage from './pages/FeaturedGravePage';

// Browser Router
const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage/>,
  },
  {
    path: "about",
    element: <AboutPage/>,
  },
  {
    path: "graves",
    element: <GravesPage/>,
    loader: async () => {
      // Fetch all profiles in this cemetery.
      return [];
    },
  },
  {
    path: "grave/:id",
    element: <FeaturedGravePage/>,
    loader: async ({ params }) => {
      // Pull data from API by ID.
      return params.id;
    },
  },
  {
    path: "grave",
    element: <FeaturedGravePage/>,
  },
  {
    path: "author/:id",
    element: <AuthorPage/>,
    loader: async ({ params }) => {
      // Pull data from API by ID.
      return params.id;
    }
  },
  {
    path: "author",
    element: <AuthorPage/>,
  }
]);

export default function App() {
  return <RouterProvider router={router} />
};