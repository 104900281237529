import React, { useState, useEffect } from 'react';
import XMark from '../icons/XMark';

import EmailSurvey from './EmailSurvey';
import CemeteryMap from './CemeteryMap';

import Clipboard from '../icons/Clipboard';
import Map from '../icons/Map';

import '../styles/toast.css';

const Toast = (props) => {
  const [popupActive, setPopupActive] = useState(false);
  const [popupContent, setPopupContent] = useState("");

  const content = {
    "survey": <EmailSurvey className="c-popup__survey"/>,
    "map": <CemeteryMap cemetery={props.cemetery} currGrave={props.currGrave ? props.currGrave : null} referrer={props.referrer}/>
  }

  useEffect(() => {
    return () => (setPopupActive(false)); // Cleanup on unmount
  }, []);

  return (
    <div>
      <div className={`c-popup ${popupActive ? 'is-active' : ''}`}>
        <div className="c-popup__inner u-position-relative">
          <button className="c-popup__exit" onClick={() => setPopupActive(false)}>
            <span className="u-visually-hidden">Exit</span>
            <XMark className="u-color-black"/>
          </button>
          {content[popupContent]}
        </div>
      </div>

      <div className={`c-toast__bar ${popupActive ? 'is-hidden' : 'is-active'}`}>
        <button 
          className="c-toast__btn u-margin-right-8" 
          onClick={() => {
            setPopupActive(true)
            setPopupContent("map")
          }}
        >
          <span className="u-visually-hidden">Survey</span>
          <Map className="c-toast__btn-icon"/>
        </button>

        <button 
          className="c-toast__btn" 
          onClick={() => {
            setPopupActive(true)
            setPopupContent("survey")
          }}
        >
          <span className="u-visually-hidden">Survey</span>
          <Clipboard className="c-toast__btn-icon"/>
        </button>
      </div>
    </div>
  );
};

export default Toast;