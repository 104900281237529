import React, { useState, useEffect } from 'react'

import ProgressiveImage from '../components/ProgressiveImage';
import Blur from '../images/Blur.jpeg';

const SwingImage = ({ id, className, imageUrl }) => {
  const [borderRadius, setBorderRadius] = useState('');

  // Randomly choose a border radius for the profile picture.
  const getBorderRadius = () => {
    const borderRadii = ['24px', '50%', '0px'];
    return borderRadii[Math.floor(Math.random() * borderRadii.length)]; 
  }

  useEffect(() => {
    // Keep the profile pic border consistent until the page is refreshed
    setBorderRadius(getBorderRadius());
  }, [id]);

  return (
    <div className="u-margin-y-16 u-display-flex u-justify-content-center">
      <div className="c-swing" style={{ '--border-radius': borderRadius }}>
        <ProgressiveImage 
          className="c-grave__intro-img"
          src={`${imageUrl}`}
          placeholderSrc={Blur}
          width="150"
        />
      </div>
    </div>
  )
};

export default SwingImage;