import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import '../styles/header.css';
import GobituaryLogoBlack from '../icons/GobituaryLogoBlack';

export default function SiteHeader() {
  const { i18n } = useTranslation();
  const [otherLanguage, setOtherLanguage] = useState(() => i18n.resolvedLanguage === 'en' ? 'es' : 'en'); 

  const lngs = {
    en: { nativeName: 'English' },
    es: { nativeName: 'Español' }
  };

  const changeLanguage = () => {
    if (i18n.resolvedLanguage === 'en') {
      i18n.changeLanguage('es');
      setOtherLanguage('en');
    } else {
      i18n.changeLanguage('en');
      setOtherLanguage('es');
    }
  }

  return (
    <nav className="c-header u-display-flex u-align-items-center u-padding-x-16">
      <a href="/">
        <div className="c-header__heading u-display-flex" aria-label="GoBituary">
          <GobituaryLogoBlack className="c-header__logo"/>
        </div>
      </a>
      <button type="submit" onClick={changeLanguage} className="c-header__lng c-button c-button--filled">
        {lngs[otherLanguage].nativeName}
      </button>
    </nav>
  )
}
