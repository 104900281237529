// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c-directory__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
}

/* Desktop version, more cards per row */
@media only screen and (min-width: 992px)  {
    .c-directory__container {
        grid-template-columns: repeat(5, 1fr);
    }
}

`, "",{"version":3,"sources":["webpack://./src/styles/directory.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qCAAqC;IACrC,QAAQ;AACZ;;AAEA,wCAAwC;AACxC;IACI;QACI,qCAAqC;IACzC;AACJ","sourcesContent":[".c-directory__container {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 8px;\n}\n\n/* Desktop version, more cards per row */\n@media only screen and (min-width: 992px)  {\n    .c-directory__container {\n        grid-template-columns: repeat(5, 1fr);\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
