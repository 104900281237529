// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c-home__hero {
  position: relative;
  height: 300px;
  margin-bottom: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.c-home__hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.c-home__title {
  font-size: 32px;
  margin: 8px 0;
  line-height: 32px;
}

.c-home__welcome {
  font-weight: 300;
  font-size: 24px;
}

.c-home__about {
  font-size: 16px;
}

/* Desktop styles. */
@media only screen and (min-width: 992px) {
  .c-home__welcome {
    font-size: 32px;
  }

  .c-home__title {
    margin: 16px 0;
    font-size: 48px;
    line-height: unset;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/home.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,4BAA4B;EAC5B,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;;AAEA;EACE,eAAe;EACf,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA,oBAAoB;AACpB;EACE;IACE,eAAe;EACjB;;EAEA;IACE,cAAc;IACd,eAAe;IACf,kBAAkB;EACpB;AACF","sourcesContent":[".c-home__hero {\n  position: relative;\n  height: 300px;\n  margin-bottom: 24px;\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n}\n\n.c-home__hero-text {\n  text-align: center;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.c-home__title {\n  font-size: 32px;\n  margin: 8px 0;\n  line-height: 32px;\n}\n\n.c-home__welcome {\n  font-weight: 300;\n  font-size: 24px;\n}\n\n.c-home__about {\n  font-size: 16px;\n}\n\n/* Desktop styles. */\n@media only screen and (min-width: 992px) {\n  .c-home__welcome {\n    font-size: 32px;\n  }\n\n  .c-home__title {\n    margin: 16px 0;\n    font-size: 48px;\n    line-height: unset;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
