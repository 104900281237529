import React, { useState, useCallback, useEffect } from 'react';
import { useAdvancedMarkerRef, AdvancedMarker, InfoWindow, Pin } from '@vis.gl/react-google-maps';
import { Link } from 'react-router-dom';

import GobituaryGraveBlack from '../icons/GobituaryGraveBlack';
import GobituaryGraveStar from '../icons/GobituaryGraveStar';

const MarkerWithInfoWindow = ({ name, headline, position, isActive, onMarkerClick, graveId, isCurrGrave, referrer }) => {
  const [markerRef, marker] = useAdvancedMarkerRef();
  const [infoWindowShown, setInfoWindowShown] = useState(false);

  const handleMarkerClick = useCallback(() => {
    onMarkerClick();
    setInfoWindowShown(true);
  }, [onMarkerClick]);

  // Synchronize InfoWindow state with parent component
  useEffect(() => {
    if (!isActive) {
      setInfoWindowShown(false);
    }
  }, [isActive]);

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        position={position}
        onClick={handleMarkerClick}
      >
        <Pin
          background={'#FFF'}
          borderColor={'#FFF'}
          glyphColor={'#1e89a1'}
        >
          {isCurrGrave ? <GobituaryGraveStar/> : <GobituaryGraveBlack/>}
        </Pin>
      </AdvancedMarker>

      {infoWindowShown && (
        <InfoWindow anchor={marker} onClose={() => setInfoWindowShown(false)}>
          <Link
            className="u-font-weight-bold"
            to={"/grave/" + graveId}
            state={{ referrer: referrer }}
            key={graveId}
            onClick={() => window.scrollTo(0, 0)}
          >{name}</Link>
          <p>{headline}</p>
        </InfoWindow>
      )}
    </>
  );
};

export default MarkerWithInfoWindow;