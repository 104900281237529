import React from 'react';
import { Link } from 'react-router-dom';

const AuthorLinks = ({ authors }) => {
  return (
    <>
      {authors.map((author, index) => 
        <span key={author.attributes.name}>
          <span>
            {index > 0 && index === (authors.length - 1) ? ' and ' : 
              index > 0 && index < (authors.length - 1) ? ', ' : ''
            }
          </span>
          <Link
            className="c-link"
            to={"/author/" + author.id}
            state={{ referrer: "grave" }}
            role="presentation"
            key={author.id}
            onClick={() => window.scrollTo(0, 0)}
          >
            {author.attributes.name}
          </Link>
        </span>
      )}
    </>
  )
}

export default AuthorLinks;