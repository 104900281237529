// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c-loading {
  padding: 1rem;
  margin: 0 auto;
  min-height: 100vh;
}

.c-loading__placeholder {
  background: #eee;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  margin-bottom: 16px;
}

.c-loading__placeholder--sm {
  height: 48px;
}

.c-loading__placeholder--m {
  height: 96px;
}

.c-loading__placeholder--l {
  height: 128px;
}

.c-loading__placeholder--xl {
  height: 208px;
}

.c-loading__placeholder:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100px;
  background: linear-gradient(90deg, #eee, #f4f4f4, #eee);
  animation: gradient 1s infinite ease-in-out;
}

@keyframes gradient {
  form {
    left: 0%;
  }
  
  to {
    left: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/loading.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,SAAS;EACT,YAAY;EACZ,uDAAuD;EACvD,2CAA2C;AAC7C;;AAEA;EACE;IACE,QAAQ;EACV;;EAEA;IACE,UAAU;EACZ;AACF","sourcesContent":[".c-loading {\n  padding: 1rem;\n  margin: 0 auto;\n  min-height: 100vh;\n}\n\n.c-loading__placeholder {\n  background: #eee;\n  border-radius: 8px;\n  position: relative;\n  overflow: hidden;\n  margin-bottom: 16px;\n}\n\n.c-loading__placeholder--sm {\n  height: 48px;\n}\n\n.c-loading__placeholder--m {\n  height: 96px;\n}\n\n.c-loading__placeholder--l {\n  height: 128px;\n}\n\n.c-loading__placeholder--xl {\n  height: 208px;\n}\n\n.c-loading__placeholder:after {\n  content: '';\n  position: absolute;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  width: 100px;\n  background: linear-gradient(90deg, #eee, #f4f4f4, #eee);\n  animation: gradient 1s infinite ease-in-out;\n}\n\n@keyframes gradient {\n  form {\n    left: 0%;\n  }\n  \n  to {\n    left: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
