import React from 'react';
import { useQuery } from '@apollo/client';
import CEMETERY from '../graphql/cemeteryById';
import { useTranslation, Trans } from 'react-i18next';

// Styles
import '../styles/home.css';

// Components
import SiteHeader from '../components/SiteHeader';
import SiteFooter from '../components/SiteFooter';
import Loading from '../components/Loading';
import ArrowRight from '../icons/ArrowRight';
import FeaturedCarousel from '../components/FeaturedCarousel';
import EmailSurvey from '../components/EmailSurvey';
import Toast from '../components/Toast';
import Citations from '../components/Citations';

// Helpers
import addLineBreak from '../helpers/addLineBreak';
import Byline from '../components/Byline';

export default function HomePage() {
  // Hardcoded id 1 for Miami City Cemetery.
  const { loading, error, data } = useQuery(CEMETERY, { variables: { id: 1 } });
  const { i18n } = useTranslation();

  if (loading) return <div><SiteHeader/><Loading/><SiteFooter/></div>;
  if (error) return <div><SiteHeader/>{error.message}<SiteFooter/></div>

  const cemetery = data.cemetery.data.attributes;

  return (
    <div>
      <SiteHeader/>
      
      <div className="c-home__hero" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${cemetery.hero.data.attributes.formats.medium.url}")` }}>
        <div className="c-home__hero-text">
          <span className="c-home__welcome u-color-white"><Trans i18nKey="home.welcomeTo">Welcome to</Trans><br/></span>
          <h2 className="c-home__title u-font-weight-bold u-color-white">{cemetery.name}</h2>
        </div>
      </div>

      <div className="c-container c-home">
        <h3 className="h3"><Trans i18nKey="home.graves">Graves</Trans></h3>
        <FeaturedCarousel className="u-margin-bottom-8" featured={cemetery.featuredGraves.data} referrer="home"/>
        <a className="c-link--button u-margin-bottom-24" href="/graves"><Trans i18nKey="home.viewAllGraves">View all graves</Trans><ArrowRight/></a>
        
        <h3 className="h3"><Trans i18nKey="home.about">About the</Trans> {cemetery.name}</h3>
        <Byline authors={cemetery.authors.data} reviewers={cemetery.reviewers.data} translators={cemetery.translators.data}/>

        {/* Sections */}
        {cemetery[i18n.resolvedLanguage + "_sections"].map(section => 
          <div key={section.heading}>
            <h3 className="h3">{section.heading}</h3>
            <p className="c-home__about u-margin-top-0 u-margin-bottom-24 u-line-break">{addLineBreak(section.text)}</p>
          </div>
        )}

        { cemetery.citations ? <Citations citations={cemetery.citations} className="u-margin-bottom-24"/> : null }

        <h3 className="h3"><Trans i18nKey="grave.giveUsFeedback">Take the survey</Trans></h3>
        <EmailSurvey className="u-margin-bottom-24"/>
      </div>

      <Toast cemetery={cemetery} referrer="home"/>
      
      <SiteFooter/>
    </div>
  )
}
