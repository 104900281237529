import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import '../styles/profile-card.css';

import User from "../icons/User";
import ProgressiveImage from './ProgressiveImage';
import Blur from '../images/Blur.jpeg';

const ProfileCard = ({ referrer, grave, attrs, className }) => {
  const { t, i18n } = useTranslation();

  return (
    <Link
      className={`c-profile-card__card ${className}`}
      to={"/grave/" + grave.id}
      state={{ referrer: referrer }}
      role="presentation"
      key={grave.id}
      onClick={() => window.scrollTo(0, 0)}
      {...attrs}
    >
      <div className="c-image__dim">
        {grave.attributes.avatar.data ?
          <ProgressiveImage
              className="c-image--fit c-profile-card__avatar"
              src={grave.attributes.avatar.data.attributes.formats?.small ? grave.attributes.avatar.data.attributes.formats.small.url : grave.attributes.avatar.data.attributes.url}
              placeholderSrc={Blur}
              alt={grave.attributes.avatar.data.attributes.alternativeText}
          />
          : <User className="c-profile-card__avatar c-avatar" />
        }
      </div>
        <p className="c-profile-card__name">{grave.attributes.givenName} {grave.attributes.surname}</p>
        {grave.attributes[i18n.resolvedLanguage + "_headline"] && <p className="c-profile-card__headline">{grave.attributes[i18n.resolvedLanguage + "_headline"]}</p>}
    </Link>
  )
}

export default ProfileCard;