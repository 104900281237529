import React from 'react'
import { useLoaderData } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

// Queries
import AUTHOR from '../graphql/authorById';

import SiteHeader from '../components/SiteHeader';
import SiteFooter from '../components/SiteFooter';
import Loading from '../components/Loading';
import SwingImage from '../components/SwingImage';

export default function AuthorPage() {
  const id = useLoaderData();
  const { loading, error, data } = useQuery(AUTHOR, { variables: { id: id } });
  const { i18n } = useTranslation();

  if (loading) return <div><SiteHeader/><Loading/><SiteFooter/></div>;
  if (error) return <div><SiteHeader/>{error.message}<SiteFooter/></div>

  const author = data.author.data.attributes;
  
  return (
    <>
      <SiteHeader/>

      <div className="u-margin-y-32">
        {author.avatar.data ?
          <SwingImage id={id} imageUrl={author.avatar.data.attributes.url}/>
        : null}
        <div className="c-container">
          <h1 className="c-grave__h1 u-font-size-20 u-text-align-center">{author.name}</h1>
          <p className="u-text-align-center">{author[i18n.resolvedLanguage + "_bio"]}</p>
        </div>
      </div>

      <SiteFooter/>
    </>
  );
}
