// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
  cursor: pointer;
}

.c-button {
  padding: 12px 16px;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 16px;
  color: var(--black);
  outline: 2px solid var(--black);
  border: none;
  background: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.c-button--filled {
  color: var(--white);
  background: var(--black);
  border: none;
}

.c-button--link {
  height: unset;
  background: transparent;
  border: none;
}

.c-clamp_button {
  cursor: pointer;
  margin-top: 16px;
}

@media only screen and (min-width: 768px) {
  .c-button:hover {
    color: var(--white);
    background: var(--black);
    transition: 0.5s;
  }

  .c-button--filled:hover {
    color: var(--black);
    outline: 2px solid var(--black);
    background: var(--white);
    transition: 0.5s;
    border: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/button.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,mBAAmB;EACnB,+BAA+B;EAC/B,YAAY;EACZ,wBAAwB;EACxB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,wBAAwB;EACxB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE;IACE,mBAAmB;IACnB,wBAAwB;IACxB,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;IACnB,+BAA+B;IAC/B,wBAAwB;IACxB,gBAAgB;IAChB,YAAY;EACd;AACF","sourcesContent":["button {\n  cursor: pointer;\n}\n\n.c-button {\n  padding: 12px 16px;\n  margin-bottom: 8px;\n  font-weight: bold;\n  font-size: 16px;\n  color: var(--black);\n  outline: 2px solid var(--black);\n  border: none;\n  background: var(--white);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 4px;\n}\n\n.c-button--filled {\n  color: var(--white);\n  background: var(--black);\n  border: none;\n}\n\n.c-button--link {\n  height: unset;\n  background: transparent;\n  border: none;\n}\n\n.c-clamp_button {\n  cursor: pointer;\n  margin-top: 16px;\n}\n\n@media only screen and (min-width: 768px) {\n  .c-button:hover {\n    color: var(--white);\n    background: var(--black);\n    transition: 0.5s;\n  }\n\n  .c-button--filled:hover {\n    color: var(--black);\n    outline: 2px solid var(--black);\n    background: var(--white);\n    transition: 0.5s;\n    border: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
