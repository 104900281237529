// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c-profile-card__card {
    --border-radius: 4px;

    border-radius: var(--border-radius);
    min-height: 140px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
    font-size: 14px;
    text-align: center;
    box-shadow: var(--shadow);
    padding: 8px;
    position: relative;
}

.c-profile-card__avatar {
    border-radius: var(--border-radius)
}

.c-profile-card__name {
    padding: 0 4px;
    margin: 0;
    font-weight: bold;
    z-index: 2; /* Above image */
    color: white;
    margin-bottom: 8px;
}

.c-profile-card__headline {
    z-index: 2; /* Above image */
    color: white;
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/profile-card.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;;IAEpB,mCAAmC;IACnC,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,qBAAqB;IACrB,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI;AACJ;;AAEA;IACI,cAAc;IACd,SAAS;IACT,iBAAiB;IACjB,UAAU,EAAE,gBAAgB;IAC5B,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,UAAU,EAAE,gBAAgB;IAC5B,YAAY;IACZ,SAAS;AACb","sourcesContent":[".c-profile-card__card {\n    --border-radius: 4px;\n\n    border-radius: var(--border-radius);\n    min-height: 140px;\n    background: white;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    text-decoration: none;\n    color: black;\n    font-size: 14px;\n    text-align: center;\n    box-shadow: var(--shadow);\n    padding: 8px;\n    position: relative;\n}\n\n.c-profile-card__avatar {\n    border-radius: var(--border-radius)\n}\n\n.c-profile-card__name {\n    padding: 0 4px;\n    margin: 0;\n    font-weight: bold;\n    z-index: 2; /* Above image */\n    color: white;\n    margin-bottom: 8px;\n}\n\n.c-profile-card__headline {\n    z-index: 2; /* Above image */\n    color: white;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
