import React from 'react';

import "../styles/directory.css";
import "../styles/avatar.css";

import ProfileCard from './ProfileCard';

const Directory = ({ graves, referrer, className }) => {
    const items = graves.map(grave =>
      <ProfileCard 
        referrer={referrer} 
        grave={grave}
      />
    );

    return (
      <div className={`c-directory__container ${className}`}>
        {items}
      </div>
    )
}

export default Directory;