// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c-avatar {
  height: var(--avatar-size);
  width: var(--avatar-size);
  object-fit: cover;
  border-radius: 50%;
  transform: translate3d(0, 0, 1px); /* Image pixelation workaround */
}`, "",{"version":3,"sources":["webpack://./src/styles/avatar.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,yBAAyB;EACzB,iBAAiB;EACjB,kBAAkB;EAClB,iCAAiC,EAAE,gCAAgC;AACrE","sourcesContent":[".c-avatar {\n  height: var(--avatar-size);\n  width: var(--avatar-size);\n  object-fit: cover;\n  border-radius: 50%;\n  transform: translate3d(0, 0, 1px); /* Image pixelation workaround */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
