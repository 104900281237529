import React, { useState, useEffect, useCallback } from 'react';
import { useLoaderData, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTranslation, Trans } from 'react-i18next';

// Queries
import GRAVE from '../graphql/graveById';

// Components
import ClampedText from '../components/ClampedText';
import FeaturedCarousel from '../components/FeaturedCarousel';
import Loading from '../components/Loading';
import SiteHeader from '../components/SiteHeader';
import ProgressiveImage from '../components/ProgressiveImage';
import Blur from '../images/Blur.jpeg';
import EmailSurvey from '../components/EmailSurvey';
import SwingImage from '../components/SwingImage';

// Styles
import '../styles/grave.css';
import Popout from '../icons/Popout';
import CheckMark from '../icons/CheckMark';
import XMark from '../icons/XMark';
import TryAgain from '../icons/TryAgain';
import SiteFooter from '../components/SiteFooter';
import AudioPlayer from '../components/AudioPlayer';
import ImageCarousel from '../components/ImageCarousel';
import Toast from '../components/Toast';

// Helpers
import addLineBreak from '../helpers/addLineBreak';
import Citations from '../components/Citations';
import AuthorLinks from '../components/AuthorLinks';

export default function FeaturedGravePage() {
  const id = useLoaderData();
  const { loading, error, data } = useQuery(GRAVE, { variables: { id: id } });

  // Hooks
  const location = useLocation();
  const { i18n } = useTranslation();
    
  // States
  const [bioTop, setBioTop] = useState(0);
  const [quizScore, setQuizScore] = useState(0);
  const [reportOpen, setReportOpen] = useState(false);
  const [currQuestionId, setCurrQuestionId] = useState(0);
  const [optionClicked, setOptionClicked] = useState(-1);
  const [quizResponses, setQuizResponses] = useState([]);
  const [fontSizeClass, setFontSizeClass] = useState('u-font-size-16');

  // Variables
  const abcd = ['A', 'B', 'C', 'D'];

  /* TO DO: Make this better? */
  const quizResContent = {
    low: {
      img: 'https://gobituary-strapi-media.s3.amazonaws.com/thumbnail_low_5156cacf10.png',
      en_copy: 'A work in progress...',
      es_copy: 'Sí se puede!'
    },
    medium: {
      img: 'https://gobituary-strapi-media.s3.amazonaws.com/thumbnail_medium_af0bab7570.png',
      en_copy: 'Pretty good!',
      es_copy: 'Muy bien!'
    },
    high: {
      img: 'https://gobituary-strapi-media.s3.amazonaws.com/thumbnail_high_355e0f131d.png',
      en_copy: 'HISTORY WIZARD!',
      es_copy: 'Eres un mago!'
    }
  }

  // Refs
  const biographyRef = useCallback(node => {
    if (node !== null) {
      setBioTop(node.offsetTop);
    }
  }, []);

  const resetQuizState = useCallback(() => {
    setQuizScore(0);
    setCurrQuestionId(0);
    setOptionClicked(-1);
    setQuizResponses([]);
    setReportOpen(false);
  }, []);

  useEffect(() => {
    // Reset quiz-related states when the id changes
    // (i.e. when the user navigates to a different grave)
    resetQuizState();
  }, [resetQuizState, id]);
  
  if (loading) return <div><SiteHeader/><Loading/><SiteFooter/></div>;
  if (error) return <div><SiteHeader/>Sorry! We can't seem to load the data.<SiteFooter/></div>

  const grave = data.grave.data.attributes;
  const graveId = data.grave.data.id;

  // console.log('Grave: ', grave);

  // Removes the current grave from the featured graves list.
  const featuredGraves = grave.cemetery.data.attributes.featuredGraves.data.filter(grave => grave.id !== id);

  // Quiz Functions
  const handleQuizNextClick = () => {
    // Reset the option clicked to -1.
    setOptionClicked(-1);

    // Update the current question.
    setCurrQuestionId((previousId) => previousId + 1);
  }

  const handleQuizClick = (optionId, isCorrect) => {
    // Update which option was clicked.
    setOptionClicked(optionId);

    const updateResponses = [
      ...quizResponses,
      {
        correct: Boolean(isCorrect),
        inputId: optionId
      }
    ];

    // Update the quiz responses and score.
    setQuizResponses(updateResponses);
    setQuizScore(prevScore => prevScore + Number(isCorrect));

    // Wait until animation is finished to continue.
    setTimeout(() => {
      handleQuizNextClick();
    }, 2000)
  }

  const getLevel = () => {
    if (quizScore/grave.quiz.length >= .7) return 'high';
    else if (quizScore/grave.quiz.length >= .5) return 'medium';
    else return 'low';
  }

  return (
    <div className="c-grave">
      <SiteHeader/>
      
      {/* Intro */}
      <div className="c-grave__intro u-position-relative" style={{ borderBottom: `${grave.wallpaper.data ? '8px solid var(--gold)' : ''}` }}>
        {grave.wallpaper.data ? 
          <ProgressiveImage 
            className="c-grave__intro-bg" 
            src={`${grave.wallpaper.data.attributes.url}`}
            placeholderSrc={Blur}
          />
        : null}
        
        {grave.avatar.data ?
          <SwingImage id={id} imageUrl={grave.avatar.data.attributes.url}/>
        : null}

        <div className="u-display-flex u-justify-content-center">
          <div className="c-grave__headline u-text-align-center u-position-relative">
            <h1 className="c-grave__h1 u-font-size-20 u-text-align-center">{grave.givenName} {grave.surname}</h1>
            <h2 className="u-font-size-16 u-font-weight-normal u-margin-0">{grave[i18n.resolvedLanguage + "_headline"]}</h2>
          </div>
        </div>
      </div>

      { grave.tapThrough.length > 0 ? <ImageCarousel tapThrough={grave.tapThrough}/> : null }
      
      {/* Biography */}
      { grave.en_bio ?
        <div ref={biographyRef} className="c-grave__bio">
          { grave.authors.data.length > 0 ? 
            <p className="u-margin-bottom-8">
              <span><Trans i18nKey="grave.by">By</Trans> </span>
              <AuthorLinks authors={grave.authors.data}/>
            </p>
          : null }

          { grave.reviewers.data.length > 0 ? 
            <p className="u-margin-bottom-16 u-margin-top-0">
              <span><Trans i18nKey="grave.reviewedBy">Reviewed by</Trans> </span>
              <AuthorLinks authors={grave.reviewers.data}/>
            </p>
          : null }

          <div className="u-display-flex u-align-items-center u-justify-content-space-between u-margin-y-16">
            { grave.en_audio.data ? <AudioPlayer audioSrc={grave[i18n.resolvedLanguage + "_audio"].data.attributes.url}/> : null }
            
            <div className="c-font__toggle">
              <div className="c-font__container" onClick={() => setFontSizeClass('u-font-size-16')}>
                <button className={`c-font__button small ${fontSizeClass === 'u-font-size-16' ? 'active' : ''}`}
                >A</button>
              </div>
              <div className="c-font__container" onClick={() => setFontSizeClass("u-font-size-20")}>
                <button className={`c-font__button large ${fontSizeClass === 'u-font-size-20' ? 'active' : ''}`}
                >A</button>
              </div>
            </div>
          </div>

          <ClampedText 
            scrollTop={bioTop} 
            className="u-line-break" 
            text={addLineBreak(grave[i18n.resolvedLanguage + "_bio"])} 
            maxLines={25} 
            buttonClass="c-button c-button--filled"
            textClass={fontSizeClass}
          />
        </div>
      : null }

      { grave.quiz.length > 0 ? 
        <div className="c-grave__container ">
          <h3 className="h3"><Trans i18nKey="grave.checkYourKnowledge">Check your knowledge</Trans></h3>
          <div className="c-quiz u-position-relative">
            <h3 className="u-display-none">Quiz</h3>
            {/* Progress Bar */}
            <div className="c-quiz__progress-outer u-margin-bottom-32">
              { grave.quiz.map((el, index) => {
                return (
                  <div key={index}>
                    { index < currQuestionId ?
                      (quizResponses[index].correct ? 
                        <div className="c-quiz__progress c-quiz__progress--check"><CheckMark className="c-quiz__progress-icon"/></div>
                        : <div className="c-quiz__progress c-quiz__progress--x"><span className="u-visually-hidden">Exit</span><XMark className="c-quiz__progress-icon"/></div>)
                      : <div className="c-quiz__progress c-quiz__progress--empty"></div>
                    }
                  </div>
              )})}
            </div>
            {
              currQuestionId < grave.quiz.length ?
                Object.keys(grave.quiz).map(id => {
                  return (
                    <div key={id} className={`c-quiz__question ${currQuestionId === parseInt(id) ? "is-active" : ""}`}>
                      <h3 className="u-font-weight-bold u-margin-bottom-24">{grave.quiz[id][i18n.resolvedLanguage + "_question"]}</h3>
                      <ul className="c-quiz__options">
                        {Object.keys(grave.quiz[id].options).map(optionId => {
                            return (
                              <li key={optionId}>
                                <button
                                  disabled={optionClicked > -1}
                                  className={`c-quiz__option-btn ${optionClicked > -1 ? "is-disabled" : ""} ${optionClicked > -1 && optionClicked === optionId ? "is-clicked" : ""} ${optionClicked > -1 && grave.quiz[id].options[optionId].correct ? "correct a-enlarge" : "incorrect"} ${optionClicked > -1 && !grave.quiz[id].options[optionId].correct && optionClicked !== optionId ? "fade-out" : "shift-up"}`}
                                  onClick={() => handleQuizClick(optionId, grave.quiz[id].options[optionId].correct)
                                }>
                                  <div className="c-quiz__letter">{abcd[optionId]}</div>
                                  <p className="u-margin-y-0 u-padding-x-24">{grave.quiz[id].options[optionId][i18n.resolvedLanguage + "_option"]}</p>
                                  <CheckMark className={`c-quiz__mark u-color-green ${optionClicked > -1 && grave.quiz[id].options[optionId].correct ? "is-active" : ""}`}/>
                                  <XMark className={`c-quiz__mark u-color-red ${optionClicked > -1 && optionClicked === optionId && !grave.quiz[id].options[optionId].correct ? "is-active" : ""}`}/>
                                </button>
                              </li>
                            );
                          })
                        }
                      </ul>
                    </div>
                  );
                }) :
              <div>
                <img
                  className="u-display-flex u-margin-0-auto"
                  src={quizResContent[getLevel()].img}
                  height={108}
                  alt={quizResContent[getLevel()][i18n.resolvedLanguage + "_copy"]}
                />
                <p className="u-font-weight-bold u-text-align-center u-font-size-32 u-margin-bottom-16">{quizResContent[getLevel()][i18n.resolvedLanguage + "_copy"]}</p>
                <div className="c-quiz__score u-margin-bottom-32">
                  <div className="u-margin-bottom-16">Score: {quizScore} / {grave.quiz.length}</div>
                  <div className="c-quiz__score-bar-container">
                    <div className={`c-quiz__score-bar ${quizScore < grave.quiz.length ? "a-score" : "a-perfect-score"}`} style={{'--bar-width': `${((quizScore/grave.quiz.length) * 200 )}px`}}></div>
                  </div>
                </div>
                <button className="c-button c-button--filled c-quiz__buttons" onClick={resetQuizState}><TryAgain className="c-quiz__restart-icon"/><Trans i18nKey="grave.tryAgain">Try Again</Trans></button>
                <button className="c-button c-quiz__buttons u-margin-top-16" onClick={() => { setReportOpen(prev => !prev) }}>
                  { reportOpen ? <Trans i18nKey="grave.hideReport">Hide Report</Trans> : <Trans i18nKey="grave.viewReport">View Report</Trans> }
                </button>
                <div className={`c-quiz__report ${reportOpen ? "a-quiz-dropdown" : ""}`}>
                  {Object.keys(grave.quiz).map(id => {
                    return (
                      <div key={id}>
                        <div>{[grave.quiz[id][i18n.resolvedLanguage + "_question"]]}</div>
                        <ul className="c-quiz__options">
                          {Object.keys(grave.quiz[id].options).map(optionId => {
                              return (
                                <li key={optionId} className="u-display-flex u-align-items-center u-position-relative">
                                  <span className={`${grave.quiz[id].options[optionId].correct ? "u-color-green u-font-weight-bold" : 
                                    quizResponses[id].inputId === optionId && !grave.quiz[id].options[optionId].correct ? "u-color-red u-font-weight-bold" : 
                                    "u-color-dark-grey"
                                  }`}>
                                    {grave.quiz[id].options[optionId][i18n.resolvedLanguage + "_option"]}
                                  </span>
                                  <CheckMark className={`c-quiz__mark u-color-green ${grave.quiz[id].options[optionId].correct ? "is-active" : ""}`}/>
                                  <XMark className={`c-quiz__mark u-color-red ${quizResponses[id].inputId === optionId && !grave.quiz[id].options[optionId].correct ? "is-active" : ""}`}/>
                                </li>
                              );
                            })
                          }
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </div> 
            }
          </div>
        </div>
      : null }

      { grave.eras.length > 0 ? 
        <div className="c-grave__container">
          <h3 className="h3"><Trans i18nKey="grave.learnMore">Learn more</Trans></h3>
          <div>
            { grave.eras.map((el, index) =>
              <a href={el.link} className="c-link--button c-grave__era-pill" key={index} target="_blank" rel="noreferrer">
                {el[i18n.resolvedLanguage + "_era"]}
                <Popout className="c-grave__era-icon"/>
              </a>
            ) }
          </div>
        </div>
      : null }

      <div className="c-grave__container">
        <h3 className="h3"><Trans i18nKey="grave.otherStories">Explore other stories</Trans></h3>
        <FeaturedCarousel featured={featuredGraves}/>
      </div>

      { grave.citations ? <Citations className="c-grave__container" citations={grave.citations}/> : null }

      <div className="c-grave__container">
        <h3 className="h3"><Trans i18nKey="grave.giveUsFeedback">Take the survey</Trans></h3>
        <EmailSurvey/>
      </div>

      <SiteFooter/>

      <Toast cemetery={grave.cemetery.data.attributes} currGrave={graveId} referrer="grave"/>
    </div>
  )
}
