// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c-site-footer {
  background-color: var(--black);
  padding: 48px 16px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.c-site-footer__about {
  margin-bottom: 24px;
}

.c-site-footer__section {
  gap: 8px;
}

.c-site-footer__icon {
  height: 16px;
}

.c-site-footer__footnote {
  font-size: 14px;
  text-align: center;
  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/styles/site-footer.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,OAAO;EACP,QAAQ;AACV","sourcesContent":[".c-site-footer {\n  background-color: var(--black);\n  padding: 48px 16px;\n  margin-top: 16px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  position: relative;\n}\n\n.c-site-footer__about {\n  margin-bottom: 24px;\n}\n\n.c-site-footer__section {\n  gap: 8px;\n}\n\n.c-site-footer__icon {\n  height: 16px;\n}\n\n.c-site-footer__footnote {\n  font-size: 14px;\n  text-align: center;\n  position: absolute;\n  bottom: 8px;\n  left: 0;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
