import { gql } from '@apollo/client';

const AUTHOR = gql`
  query GetAuthor($id: ID!) {
    author(id: $id) {
      data {
        id,
        attributes {
          name,
          en_bio,
          es_bio,
          avatar {
            data {
              attributes {
                url
                formats
                alternativeText
              }
            }
          }
        }
      }
    }
  }
`

export default AUTHOR;