// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c-email__input {
  font-size: 16px;
  min-width: 200px;
  padding: 4px 8px;
}

.c-email__check {
  background: var(--light-blue);
  border: 1px solid var(--dark-blue);
  height: 36px;
  width: 36px;
  border-radius: 50%;
}

.c-email__check--success {
  background: var(--light-blue);
  border: 1px solid var(--dark-blue);
}

.c-email__check--fail {
  background: var(--white);
  border: 1px solid var(--red);
}

.c-email__check-icon--success {
  color: var(--dark-blue);
}

.c-email__check-icon--fail {
  color: var(--red);
}`, "",{"version":3,"sources":["webpack://./src/styles/email.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,kCAAkC;EAClC,YAAY;EACZ,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;EAC7B,kCAAkC;AACpC;;AAEA;EACE,wBAAwB;EACxB,4BAA4B;AAC9B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".c-email__input {\n  font-size: 16px;\n  min-width: 200px;\n  padding: 4px 8px;\n}\n\n.c-email__check {\n  background: var(--light-blue);\n  border: 1px solid var(--dark-blue);\n  height: 36px;\n  width: 36px;\n  border-radius: 50%;\n}\n\n.c-email__check--success {\n  background: var(--light-blue);\n  border: 1px solid var(--dark-blue);\n}\n\n.c-email__check--fail {\n  background: var(--white);\n  border: 1px solid var(--red);\n}\n\n.c-email__check-icon--success {\n  color: var(--dark-blue);\n}\n\n.c-email__check-icon--fail {\n  color: var(--red);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
