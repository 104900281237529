// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c-header {
  height: 56px;
  border-bottom: 1px solid var(--light-grey);
  position: relative;
}

.c-header__heading {
  margin: 0;
}

.c-header__lng {
  position: absolute;
  right: 8px;
  font-size: 14px;
  margin: 4px;
}

/* Desktop styles. */
@media only screen and (min-width: 768px) {
  .c-header__logo {
    width: 100px;
    height: 100%;
  }

  .c-header__lng {
    font-size: 16px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/header.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,0CAA0C;EAC1C,kBAAkB;AACpB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,eAAe;EACf,WAAW;AACb;;AAEA,oBAAoB;AACpB;EACE;IACE,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".c-header {\n  height: 56px;\n  border-bottom: 1px solid var(--light-grey);\n  position: relative;\n}\n\n.c-header__heading {\n  margin: 0;\n}\n\n.c-header__lng {\n  position: absolute;\n  right: 8px;\n  font-size: 14px;\n  margin: 4px;\n}\n\n/* Desktop styles. */\n@media only screen and (min-width: 768px) {\n  .c-header__logo {\n    width: 100px;\n    height: 100%;\n  }\n\n  .c-header__lng {\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
