import { useState, useEffect, useRef } from "react";
import { useTranslation, Trans } from 'react-i18next';

import '../styles/button.css';
import '../index.css';

const ClampedText = ({ className, buttonClass, textClass, text, maxLines, scrollTop }) => {
  const [isClamped, setIsClamped] = useState(true);
  const [showButton, setShowButton] = useState(true);
  const containerRef = useRef(null);

  const { i18n } = useTranslation();
  
  const handleClick = () => {
    // Scroll back to the top of the section when they collapse the text.
    if (!isClamped) {
      window.scrollTo({
        top: scrollTop,
        behavior: "smooth"
      })

      // Wait until scroll is over to un-clamp.
      setTimeout(() => {
        setIsClamped(!isClamped);
      }, 600)
    } else {
      const scrollPosition = window.scrollY;
      setIsClamped(!isClamped);
      setTimeout(() => {
        window.scrollTo(0, scrollPosition);
      }, 0);
    }
  }

  useEffect(() => {
    const hasClamping = (el) => {
      const { clientHeight, scrollHeight } = el;
      return clientHeight !== scrollHeight;
    };

    const checkButtonAvailability = () => {
      if (containerRef.current) {
        // Save current state to reapply later if necessary.
        const hadClampClass = containerRef.current.classList.contains("c-clamp");
        // Make sure that CSS clamping is applied if applicable.
        if (!hadClampClass) containerRef.current.classList.add("c-clamp");
        // Check for clamping and show or hide button accordingly.
        setShowButton(hasClamping(containerRef.current));
        // Sync clamping with local state.
        if (!hadClampClass) containerRef.current.classList.remove("c-clamp");
      }
    };

    checkButtonAvailability();
  }, [containerRef])

  return (
    <div className={className}>
      <div 
        ref={containerRef} 
        className={`${isClamped && showButton ? 'c-clamp' : ''} ${textClass}`} 
        style={{WebkitLineClamp: maxLines}}
      >
        {text}
      </div>
      {showButton && (
        <button onClick={handleClick} className={`c-clamp_button ${buttonClass}`}>{isClamped ? <Trans i18nKey="grave.readMore">Read more</Trans> : <Trans i18nKey="grave.readLess">Read less</Trans>}</button>
      )}
    </div>
  )

}

export default ClampedText;