import React from 'react'
import { useQuery } from '@apollo/client';
import CEMETERY from '../graphql/cemeteryById';

// Components
import Directory from '../components/Directory'
import SiteHeader from '../components/SiteHeader'
import Loading from '../components/Loading'

export default function GravesPage() {
  // TODO: Don't hardcode cemetery ID.
  const cemetery = useQuery(CEMETERY, { variables: { id: 1 } });

  if (cemetery.loading) return <div><SiteHeader/><Loading/></div>;
  if (cemetery.error) return <div><SiteHeader/>{cemetery.error.message}</div>

  return (
    <div>
      <SiteHeader/>
      <Directory className="c-container u-padding-y-16" graves={cemetery.data.cemetery.data.attributes.graves.data} referrer="directory"/>
    </div>
  )
}
