// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c-link--button {
  background: var(--white);
  padding: 12px 18px;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  box-sizing:border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--black);
  color: var(--black);
  border-radius: 4px;
  font-weight: 500;
}

.c-link {
  border-bottom: 1px solid var(--black);
  text-decoration: none;
  color: var(--black);
  font-weight: 600;
}

@media only screen and (min-width: 768px) {
  .c-link--button:hover {
    color: var(--white);
    background: var(--black);
    transition: 0.5s;
    text-decoration: underline;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/link.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,qBAAqB;EACrB,qBAAqB;EACrB,WAAW;EACX,qBAAqB;EACrB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,qCAAqC;EACrC,qBAAqB;EACrB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE;IACE,mBAAmB;IACnB,wBAAwB;IACxB,gBAAgB;IAChB,0BAA0B;EAC5B;AACF","sourcesContent":[".c-link--button {\n  background: var(--white);\n  padding: 12px 18px;\n  text-decoration: none;\n  display: inline-block;\n  width: 100%;\n  box-sizing:border-box;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border: 1px solid var(--black);\n  color: var(--black);\n  border-radius: 4px;\n  font-weight: 500;\n}\n\n.c-link {\n  border-bottom: 1px solid var(--black);\n  text-decoration: none;\n  color: var(--black);\n  font-weight: 600;\n}\n\n@media only screen and (min-width: 768px) {\n  .c-link--button:hover {\n    color: var(--white);\n    background: var(--black);\n    transition: 0.5s;\n    text-decoration: underline;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
