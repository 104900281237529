// Dependencies
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

// Styles
import './index.css';
import './styles/button.css';
import './styles/link.css';
import './styles/avatar.css';
import './styles/animations.css';

import App from './App';

import './i18n';

// let url = process.env.REACT_APP_DEVELOPMENT_URL;

// if (process.env.NODE_ENV === 'production') {
//   url = process.env.REACT_APP_PRODUCTION_URL;
// }

// Apollo Client
const client = new ApolloClient({
  uri: `${process.env.REACT_APP_PRODUCTION_URL}/graphql`,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App/>
    </ApolloProvider>
  </React.StrictMode>
);
