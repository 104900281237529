import React from 'react';

import '../styles/loading.css';

export default function Loading() {
  return (
    <div className="c-loading">
      <div className="c-loading__placeholder c-loading__placeholder--sm"></div>
      <div className="c-loading__placeholder c-loading__placeholder--m"></div>
      <div className="c-loading__placeholder c-loading__placeholder--sm"></div>
      <div className="c-loading__placeholder c-loading__placeholder--xl"></div>
      <div className="c-loading__placeholder c-loading__placeholder--l"></div>
    </div>
  )
}
