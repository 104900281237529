// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c-featured-slider .alice-carousel__prev-btn {
  left: -12px;
}

.c-featured-slider .alice-carousel__next-btn {
  right: -12px;
}

.c-featured-slider .alice-carousel__prev-btn,
.c-featured-slider .alice-carousel__next-btn {
  width: 36px;
  height: 36px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/featured-slider.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;;EAEE,WAAW;EACX,YAAY;AACd","sourcesContent":[".c-featured-slider .alice-carousel__prev-btn {\n  left: -12px;\n}\n\n.c-featured-slider .alice-carousel__next-btn {\n  right: -12px;\n}\n\n.c-featured-slider .alice-carousel__prev-btn,\n.c-featured-slider .alice-carousel__next-btn {\n  width: 36px;\n  height: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
