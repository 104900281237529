import { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import '../styles/audio.css';

import Play from '../icons/Play';
import Pause from '../icons/Pause';
import SkipBack from '../icons/SkipBack';
import XMark from '../icons/XMark';

const AudioPlayer = ({ audioSrc }) => {
  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [openPlayer, setOpenPlayer] = useState(false);

  const audioRef = useRef();
  const progressBarRef = useRef();
  const playAnimationRef = useRef();
  const { i18n } = useTranslation();

  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration;
    setDuration(seconds);
    progressBarRef.current.max = seconds;
  };

  const handleProgressChange = () => {
    audioRef.current.currentTime = progressBarRef.current.value;
  };

  const formatTime = (time) => {
    if (time && !isNaN(time)) {
      const minutes = Math.floor(time / 60);
      const formatMinutes =
        minutes < 10 ? `${minutes}` : `${minutes}`;
      const seconds = Math.floor(time % 60);
      const formatSeconds =
        seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${formatMinutes}:${formatSeconds}`;
    }
    return '00:00';
  };

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  const repeat = useCallback(() => {
    if (audioRef.current) {
      const currentTime = audioRef.current.currentTime;
      setTimeProgress(currentTime);
      progressBarRef.current.value = currentTime;
      progressBarRef.current.style.setProperty(
        '--range-progress',
        `${(progressBarRef.current.value / duration) * 100}%`
      );
      playAnimationRef.current = requestAnimationFrame(repeat);
    }
  }, [duration]);

  useEffect(() => {
    const audioElement = audioRef.current;
    if (isPlaying) {
      audioElement.play();
    } else {
      audioElement.pause();
    }

    playAnimationRef.current = requestAnimationFrame(repeat);

    const handleEnded = () => {
      setIsPlaying(false);
    };

    audioElement.addEventListener('ended', handleEnded);

    return () => {
      audioElement.removeEventListener('ended', handleEnded);
      cancelAnimationFrame(playAnimationRef.current);
     } // Cleanup on unmount
  }, [isPlaying, repeat]);

  const skipBackward = () => {
    audioRef.current.currentTime -= 5;
  };

  const openAudioPlayer = () => {
    setOpenPlayer(true);
    setIsPlaying(true);
  }

  const closeAudioPlayer = () => {
    setOpenPlayer(false);
    setIsPlaying(false);
  }

  return (
    <>
      <button onClick={openAudioPlayer} className="c-audio__listen u-display-flex u-align-items-center u-font-weight-bold"><Play className="c-audio__listen-icon"/><Trans i18nKey="grave.listenToThisStory">Listen to this story</Trans></button>
      <div className={`c-audio ${openPlayer ? 'is-active' : ''}`}>
        <div className="c-audio__inner u-display-flex">
          <audio src={audioSrc} ref={audioRef} onLoadedMetadata={onLoadedMetadata}/>
          <button className="c-audio__play-btn" onClick={togglePlayPause}>{isPlaying ? <Pause className="c-audio__play-icon c-audio__icon"/> : <Play className="c-audio__play-icon c-audio__icon"/>}</button>
          <button className="c-audio__button" onClick={skipBackward}><SkipBack className="c-audio__icon"/></button>
          <div className="u-white-space-nowrap u-font-size-12 u-color-black u-margin-right-4">{formatTime(timeProgress)} / {formatTime(duration)}</div>
          <input
            className="c-audio__input"
            type="range"
            ref={progressBarRef}
            defaultValue="0"
            onChange={handleProgressChange}
          />
          <button onClick={closeAudioPlayer} className="c-audio__close u-display-flex u-align-items-center u-justify-content-center">
            <span className="u-visually-hidden">Exit</span>
            <XMark className="c-audio__close-x"/>
          </button>
        </div>
      </div>
    </>
  );
};
export default AudioPlayer;