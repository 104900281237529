import React, { useRef, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useTranslation, Trans } from 'react-i18next';

import ChevronLeft from '../icons/ChevronLeft';
import ChevronRight from '../icons/ChevronRight';

import ProgressiveImage from '../components/ProgressiveImage';
import Blur from '../images/Blur.jpeg';
import XMark from '../icons/XMark';
import Expand from '../icons/Expand';

const handleDragStart = (e) => e.preventDefault();

const responsive = {
  0: { items: 1 },
  768: { items: 2 }
};

const renderPrevButton = ({ isDisabled }) => {
	return <span className="u-display-flex" style={{ opacity: isDisabled ? '0.2' : 1 }}><ChevronLeft/></span>;
};

const renderNextButton = ({ isDisabled }) => {
	return <span className="u-display-flex" style={{ opacity: isDisabled ? '0.2' : 1 }}><ChevronRight/></span>;
};

const ImageCarousel = ({ tapThrough }) => {
	const { i18n } = useTranslation();

  const [fullScreenSlider, setFullScreenSlider] = useState(false);

  const slider1 = useRef();
  const slider2 = useRef();

  const syncSlides = (e, ref) => {
    ref.current.slideTo(e.slide);
  };

  const items = tapThrough.map((el, index) =>
    <div key={index} className="c-grave-slider__slide" onDragStart={handleDragStart}>
      <button className="c-grave-slider__open" onClick={() => setFullScreenSlider(true)}>
        <Expand className="c-grave-slider__open-icon"/>
        <span className="u-visually-hidden">Enlarge Image</span>
      </button>
      { el.photo ? 
        <ProgressiveImage 
          className="c-grave-slider__image" 
          src={`${el.photo.data.attributes.formats?.small ? el.photo.data.attributes.formats.small.url : el.photo.data.attributes.url}`}
          placeholderSrc={Blur}
          alt={el.photo.data.attributes.alternativeText}
        />
			: null }
			{ el.en_description ? <div className="c-grave-slider__desc"><p className="c-grave-slider__desc-inner u-margin-0">{el[i18n.resolvedLanguage + "_description"]}</p></div> : null }
			{ el.source ? <div className="c-grave-slider__src"><Trans i18nKey="grave.source">Source</Trans>: {el.source}</div> : null }
    </div>
  );

  return (
    <>
      <div className="c-slider c-grave-slider">
        <AliceCarousel
          mouseTracking 
          items={items} 
          responsive={responsive} 
          disableDotsControls
          renderPrevButton={renderPrevButton}
          renderNextButton={renderNextButton}
          paddingLeft={24}
          paddingRight={24}
          ref={slider1}
          onSlideChanged={e => syncSlides(e, slider2)}
        />
      </div>
      <div className={`c-slider c-grave-slider c-grave-slider--fs ${fullScreenSlider ? 'is-active' : ''}`}>
        <button onClick={() => setFullScreenSlider(false)} className="c-grave-slider__exit">
          <XMark className="c-grave-slider__exit-icon"/>
          <span className="u-visually-hidden">Close Full-Screen Image Gallery</span>
        </button>
        <AliceCarousel
          mouseTracking 
          items={items} 
          responsive={responsive} 
          disableDotsControls
          renderPrevButton={renderPrevButton}
          renderNextButton={renderNextButton}
          ref={slider2}
          onSlideChanged={e => syncSlides(e, slider1)}
        />
      </div>
    </>
  );
}

export default ImageCarousel;