import React from 'react';

const SkipBack = ({ className }) => {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 4V10H7" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.51 14.9999C4.15839 16.8403 5.38734 18.4201 7.01166 19.5013C8.63598 20.5825 10.5677 21.1065 12.5157 20.9944C14.4637 20.8823 16.3226 20.1401 17.8121 18.8797C19.3017 17.6193 20.3413 15.9089 20.7742 14.0063C21.2072 12.1037 21.0101 10.1119 20.2126 8.33105C19.4152 6.55019 18.0605 5.07674 16.3528 4.13271C14.6451 3.18868 12.6769 2.82521 10.7447 3.09707C8.81245 3.36892 7.02091 4.26137 5.64 5.63995L1 9.99995" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.2457 16.0994C11.7438 16.0994 11.2964 16.0071 10.9034 15.8224C10.5128 15.6378 10.2027 15.3833 9.97301 15.0589C9.74337 14.7346 9.62382 14.3629 9.61435 13.9439H11.1058C11.1224 14.2256 11.2408 14.4541 11.4609 14.6293C11.6811 14.8045 11.9427 14.892 12.2457 14.892C12.4872 14.892 12.7003 14.8388 12.8849 14.7322C13.072 14.6233 13.2176 14.473 13.3217 14.2812C13.4283 14.0871 13.4815 13.8646 13.4815 13.6136C13.4815 13.358 13.4271 13.133 13.3182 12.9389C13.2116 12.7448 13.0637 12.5933 12.8743 12.4844C12.6849 12.3755 12.4683 12.3198 12.2244 12.3175C12.0114 12.3175 11.8042 12.3613 11.603 12.4489C11.4041 12.5365 11.2491 12.656 11.1378 12.8075L9.7706 12.5625L10.1151 8.72727H14.5611V9.98438H11.3828L11.1946 11.8061H11.2372C11.3651 11.6262 11.558 11.477 11.8161 11.3587C12.0741 11.2403 12.3629 11.1811 12.6825 11.1811C13.1205 11.1811 13.5111 11.2841 13.8544 11.4901C14.1977 11.696 14.4688 11.9789 14.6676 12.3388C14.8665 12.6963 14.9647 13.1082 14.9624 13.5746C14.9647 14.0646 14.8511 14.5002 14.6214 14.8814C14.3942 15.2602 14.0758 15.5585 13.6662 15.7763C13.259 15.9917 12.7855 16.0994 12.2457 16.0994Z" fill="black"/>
    </svg>
  )
}

export default SkipBack;