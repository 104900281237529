import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import '../styles/featured-slider.css';
import ProfileCard from './ProfileCard';

import ChevronLeft from '../icons/ChevronLeft';
import ChevronRight from '../icons/ChevronRight';

const handleDragStart = (e) => e.preventDefault();

const responsive = {
  0: { items: 2 }
};

const renderPrevButton = ({ isDisabled }) => {
	return <span className="u-display-flex" style={{ opacity: isDisabled ? '0.5' : 1 }}><ChevronLeft/></span>;
};

const renderNextButton = ({ isDisabled }) => {
	return <span className="u-display-flex" style={{ opacity: isDisabled ? '0.5' : 1 }}><ChevronRight/></span>;
};

const FeaturedCarousel = ({ featured, referrer, className }) => {
  const items = featured.map(grave =>
    <ProfileCard 
      className="u-margin-x-4"
      referrer={referrer} 
      grave={grave} 
      attrs={{ onDragStart: handleDragStart }}
    />
  );

  return (
    <div className={`c-slider c-featured-slider ${className}`}>
      <AliceCarousel
        mouseTracking 
        items={items} 
        responsive={responsive} 
        disableDotsControls
        renderPrevButton={renderPrevButton}
        renderNextButton={renderNextButton}
      />
    </div>
  );
}

export default FeaturedCarousel;