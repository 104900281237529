import React from 'react';
import { Trans } from 'react-i18next';

const Citations = ({ citations, className }) => {
  return (
    <div className={className}>
      <h3 className="h3"><Trans i18nKey="grave.citations">Citations</Trans></h3>
      <div className="u-font-size-16 u-margin-bottom-8">
        {citations.map(el => <a className="u-text-decoration-dotted u-color-black u-display-block u-margin-bottom-8" href={el.link} key={el.text}>{el.text}</a>)}
      </div>
    </div>
  );
}

export default Citations;